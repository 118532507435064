@import "https://fonts.googleapis.com/css?family=Arimo:300,300i,400,400i,500,500i";
@import "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/css/flag-icon.min.css";

body {
  margin: 0;
  background: #fafafa;
}
* {
  box-sizing: border-box;
  font-family: Arimo,Arial,sans-serif;
  color: #333333;
  font-size: 16px;
}
.overflow-hidden {
  overflow: hidden;
}
#root {
  position: relative;
  @media (min-width: 415px){
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
  }
}
.form-control:disabled, .form-control[readonly] {
    background-color: #fafafa;
    opacity: 1;
}
label.two-lines {
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
}
.modal-backdrop {
  background: rgba(0,0,0,0.2);
}
.passport-location {
  color: red;
  font-weight: 400;
  span {
    margin-left: 5px;
    color: #000;
  }
}
.spinner {
  color: #fff;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}
